$xsmall: "all and (min-width: 350px)";
$small: "all and (min-width: 500px)";
$medium: "all and (min-width: 700px)";
$columns: "all and (min-width: 780px)";
$large: "all and (min-width: 900px)";
$xlarge: "all and (min-width: 1100px)";
$xxlarge: "all and (min-width: 1300px)";
$xxxlarge: "all and (min-width: 1500px)";

@mixin respond($media) {
  @if $media == xsmall {
	@media #{$xsmall} { @content; }
  }
  @if $media == small {
	@media #{$small} { @content; }
  }
  @if $media == medium {
	@media #{$medium} { @content; }
  }
  @if $media == columns {
	  @media #{$columns} { @content; }
	}
   @if $media == large {
	@media #{$large} { @content; }
  }
  @if $media == xlarge {
	@media #{$xlarge} { @content; }
  }
  @if $media == xxlarge {
	@media #{$xxlarge} { @content; }
  }
  @if $media == xxxlarge {
	@media #{$xxxlarge} { @content; }
  }
}

$pad-small: .9375rem;
$pad-large: 1.25rem;

$gray-dark: #575a5c;
$gray-mid: #717171;
$gray-light: #d8d7d5;
$gray-white: #f1f0ef;

%pseudo {
	bottom: 0;
	display: block;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
}


/* alps wp workarounds */

body.template-full-width.hide-sabbath article.c-article {
	margin-left: 0;
	padding-right: 0;
	width: 100vw;
}

.template-full-width .l-main__content {
	margin-top: 0;
	.u-spacing {
		.panel, .c-breakout-image {
			margin-top: 0;
		}
	}
}

.panel {

}

.panel-pad--large {
	@include respond(large){
		padding-left: calc((100vw/7)*1);
	}
}

.panel-with-background {
	position: relative;
	.panel__background {
		color: #fff;
		padding-top: 15.625rem;
		padding-block: 1.25rem;
		position: relative;
		@include respond(medium){
			padding-top: 21.875rem;
		}
		@include respond(large){
			padding-top: 28.125rem;
			padding-left: calc((100vw/7)*1);
			padding-right: calc((100vw/7)*1);
		}
		>* {
			z-index: 10;
		}
	}
	&:after {
		@extend %pseudo;
		background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	}
}

.hide-sabbath {
	.panel-pad--large {
		@include respond(large){
			padding-right: calc((100vw/7)*1);
		}
	}
}

.wp-block-columns {
	.wp-block-column:not(:first-child) {
		margin-left: 0;
		@include respond(columns){
			margin-left: 1.25rem;
		}
	}
	>* {
		margin: 1.25rem 0;
	}
	&:first-child {
		@include respond(columns){
			margin-left: 0;
		}
	}
}

.noquotes {
	p:before, p:after {
		display: none !important;
	}
	p {
		text-indent: 0 !important;
	}
}

.c-carousel__item--inset h2 {
	color: #fff !important;
}

.c-carousel {
	.l-container {
		margin: 0;
	}
}

.hide-sabbath {
	.c-header__logo svg, .c-header__logo img {
		max-width: 13rem;
		@include respond(medium){
			max-width: 15rem;
		}
		@include respond(xlarge){
			max-width: 20rem;
		}
	}
}

.slick-dots {
	li:before {
		display: none !important;
	}
}

#footer-carousel {
	width: 100%;
	.c-media-block {
		@include respond(xxlarge){
			//width: calc(86vw/5 * 1) !important;
		}
	}
	.o-arrow {
		align-items: center;
		align-content: center;
		background: #aaa;
		color: #fff;
		border-radius: 1em;
		display: flex;
		font-size: 1em;
		height: 1.5em;
		line-height: .5em;
		position: absolute;
		top: 30%;
		width: 1.5em;
		z-index: 1000;
	}
	.o-arrow--prev {
		left: 0;
	}
	.o-arrow--next {
		right: 0;
	}
}

.brz {
	.brz-section__content {
		padding: 5vw 20vw;
	}
}

.vce-row.vce-element--has-background {
	padding: 5vw 20vw;
}

.sp-el-section {
	>*:not(pre) {
		padding: 5vw 15vw;
	}
}

.elementor-section-boxed {
	padding: 5vw 15vw;
}

.wp-block-uagb-container.padding-root {
	padding: 5vw 15vw;
}


// builder styles
%pad-content-width {
	padding-left: 1.25rem !important;
	padding-right: 1.25rem !important;
	@include respond(large){
		padding-left: 16vw !important;
		padding-right: 16vw !important;
	}
	@include respond(xxlarge){
		padding-left: 20vw !important;
		padding-right: 20vw !important;
	}
}

%u-padding {
	padding: calc(1.25rem/2 * 1.5);
	@include respond(large){
		padding: 1.25rem;
	}
}

%u-padding--left {
	padding-left: calc(1.25rem/2 * 1.5);
	@include respond(large){
		padding-left: 1.25rem;
	}
}

%u-padding--right {
	padding-right: calc(1.25rem/2 * 1.5);
	@include respond(large){
		padding-right: 1.25rem;
	}
}

%u-padding--top {
	padding-top: calc(1.25rem/2 * 1.5);
	@include respond(large){
		padding-top: 1.25rem;
	}
}

%u-padding--bottom {
	padding-bottom: calc(1.25rem/2 * 1.5);
	@include respond(large){
		padding-bottom: 1.25rem;
	}
}

main, .editor-styles-wrapper {
	.c-carousel__item--inset .c-carousel__item-text__wrap {
		@include respond(xxlarge){
			padding: 7rem 19vw 2rem;
		}
	}
	.is-layout-constrained, .pad-content-width {
		@extend %pad-content-width;
	}
	.dark {
		* {
			color: #fff;
		}
		.descCard, .contentCard {
			* {
				color: $gray-dark;
			}
		}
	}
	.c-hero {
		.slick-dots {
			top: auto;
			bottom: 0;
		}
	}
	.u-border--left {
		border-left: 1px solid #717171;
		.uagb-ifb-content {
			>*:not(.uagb-ifb-image-content,.u-spacing) {
				@extend %u-padding--left;
				margin-bottom: 3%;
			}
		}
	}
	.u-spacing>*+* {
		margin-top: calc(1.25rem/2 * 1.5);
		@include respond(large){
			margin-top: 1.25rem;
		}
	}
	.u-padding--left {
		@extend %u-padding--left;
	}
}

.c-header--overlay {
	.c-header__logo {
		img {
			filter: invert(100%);
		}
	}
	.c-header__nav-primary {
		display: none;
		@include respond(large){
			display: flex;
		}
	}
}

.hide-sabbath {
	.c-header--overlay .c-header--inner {
		width: 100vw;
	}
}
.hide-sabbath--until-small {
	.c-header--overlay .c-header--inner {
		width: 100vw;
		@include respond(small){
			width: calc((100vw/7) * 6 - 0.01px);
		}
	}
}
.hide-sabbath--until-medium {
	.c-header--overlay .c-header--inner {
		width: 100vw;
		@include respond(medium){
			width: calc((100vw/7) * 6 - 0.01px);
		}
	}
}
.hide-sabbath--until-large {
	.c-header--overlay .c-header--inner {
		width: 100vw;
		@include respond(large){
			width: calc((100vw/7) * 6 - 0.01px);
		}
	}
}